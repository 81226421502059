import { redirectToLogin } from '../../../server'
import { state } from '../../../store/state'
import { User } from '../../../store/user'
import { router } from '../../../routing/router'

export const logout = async () => {
  await (window as any).server.logout()
  redirectToLogin()
}

export const displayedName = (user: User | undefined) => {
  if (!user) {
    return ''
  }

  return !user.firstName && !user.lastName ? user.username : `${user.firstName} ${user.lastName}`
}

export const onExperimentalToggle = () => {
  router(state).upsertQueryParams({ experimental: true }).andStay()
}

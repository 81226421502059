<script lang="ts">
  import { atom } from 'nanostores'
  import RelatedContentItem from '../../atoms/content-list-item.svelte'
  import { ClipboardItem, ANIMATION_DURATION } from '../../../store/clipboard'
  import { State } from '../../../store/state'

  export let item: ClipboardItem
  export let state: State
  export let classes = atom<string>('')

  const duration = ANIMATION_DURATION / 1000 + 's'
  $: additionalClasses = $classes || ''
</script>

<div
  style={`
  --animation-duration: ${duration};  
`}
  class={`${additionalClasses} flex flex-row items-center justify-between gap-1`}
>
  <RelatedContentItem
    bind:content={item}
    alwaysShowRemove={true}
    dragSourceId="clipboard-item-{item?.id}"
    fontWeight="semibold"
    remove={() => {
      state.clipboard.removeItem(item)
    }}
  ></RelatedContentItem>
</div>

<style>
  @import './animations.css';

  .evicted {
    animation-name: slideOutRight;
    animation-duration: var(--animation-duration);
    animation-fill-mode: forwards;
  }

  .move-down {
    animation-name: slideInTop;
    animation-duration: var(--animation-duration);
  }
</style>

import { type WritableAtom, atom } from 'nanostores'
import type { Tabs } from '../../../store/tabs'
import { NullTab, TabData } from '../../../store/tabs-types'

export class TabsVisibility {
  visibleTabs: WritableAtom<TabData[]> = atom([])
  hiddenTabs: WritableAtom<TabData[]> = atom([])
  tabWidth = 192
  dropdownButtonWidth = 50
  containerWidth = 0

  constructor(private tabs: Tabs) {}

  distributeTabs() {
    const visible: TabData[] = []
    const hidden: TabData[] = []
    let totalWidth = 0

    const allTabs = this.tabs.tabs

    allTabs.get().forEach((tab) => {
      if (totalWidth + this.tabWidth <= this.containerWidth - this.dropdownButtonWidth) {
        visible.push(tab)
        totalWidth += this.tabWidth
      } else {
        hidden.push(tab)
      }
    })

    this.visibleTabs.set(visible)
    this.hiddenTabs.set(hidden)

    if (hidden.length > 0) {
      this.moveSelectedTab()
    }
  }

  moveSelectedTab() {
    const selected = this.tabs.selected.get()

    const visible = this.visibleTabs.get()
    const hidden = this.hiddenTabs.get()

    if (!visible.length) {
      return
    }

    if (!selected || selected instanceof NullTab) {
      return
    }

    const tabToMove = hidden.findIndex((tab) => tab.contentId === selected?.contentId)

    if (tabToMove === -1) {
      return
    }

    const lastVisible = visible[visible.length - 1]

    visible.pop()

    visible.push(hidden[tabToMove])

    hidden.splice(tabToMove, 1)

    hidden.unshift(lastVisible)

    this.hiddenTabs.set(hidden)
    this.visibleTabs.set(visible)
  }

  updateContainerWidth = (newWidth?: number) => {
    if (newWidth) {
      this.containerWidth = newWidth
      this.distributeTabs()
      return
    }

    const container = document.querySelector('.tabs-container')
    if (!container) {
      return
    }

    this.containerWidth = container.clientWidth
    this.distributeTabs()
  }
}

<script lang="ts">
  import Unknown from 'carbon-icons-svelte/lib/Unknown.svelte'

  import { onMount } from 'svelte'

  import routes from '../../routing/routes'

  export let iconId: string | null = null
  let iconRenderer: any

  onMount(() => {
    const i = routes.findIndex((e) => e.id === iconId)
    if (i === -1) {
      iconRenderer = Unknown
      return
    }
    iconRenderer = routes[i].icon
  })
</script>

<div class="w-6">
  <svelte:component this={iconRenderer} />
</div>

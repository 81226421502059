<script>
  $$props
</script>

<svg id="Livello_3" data-name="Livello 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.5 135.5">
  <path
    class="cls-1"
    d="m69.25,35.31c-17.63,0-31.98,14.35-31.98,31.98s14.35,31.98,31.98,31.98,31.98-14.35,31.98-31.98-14.35-31.98-31.98-31.98h0Z"
  />
  <path
    class="cls-1"
    d="m28.65,67.29c0,22.39,18.21,40.6,40.6,40.6s40.6-18.21,40.6-40.6-18.21-40.6-40.6-40.6h0"
  />
  <line class="cls-1" x1="69.25" y1="54.58" x2="69.25" y2="80" />
  <line class="cls-1" x1="56.55" y1="67.29" x2="81.96" y2="67.29" />
</svg>

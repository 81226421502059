<script lang="ts">
  import { onMount } from 'svelte'

  import NewContent from './new-content.svelte'
  import Tabs from './tabs/tabs.svelte'
  import UserMenu from './user-menu/user-menu.svelte'
  import Clipboard from './clipboard/clipboard.svelte'

  import { state } from '../../store/state'

  const tabs = state.tabs
  const connectionStatus = state.connectionStatus

  onMount(() => {
    tabs.restore()
  })
</script>

<div>
  {#if $connectionStatus === 'disconnected'}
    <div class="fixed flex z-50 flex-col content-center items-center min-w-full">
      <div class="flex min-h-[2rem] min-w-[50%] bg-error text-white text-center justify-center items-center">
        DISCONNECTED
      </div>
    </div>
  {/if}
  <div class="top-bar">
    <div class="flex w-full">
      <NewContent />
      <Tabs />
    </div>
    <div class="flex min-w-fit">
      <Clipboard />
      <UserMenu />
    </div>
  </div>
</div>

<style lang="postcss">
  .top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--accent);
    max-height: min-content;
    max-width: calc(100vw - var(--bars-size));
    min-width: 100%;
  }

  .top-bar :global(div.tab-container:not(.active) button:hover),
  .top-bar :global(button.my-button:not(.active):hover) {
    background-color: rgba(var(--hover-color-rgb), 0.1);
  }
</style>

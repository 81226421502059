<script lang="ts">
  import Add from '../../assets/icons/add-svg.svelte'

  import { state } from '../../store/state'
  import MyButton from '../atoms/my-button.svelte'

  const { isLoading } = state

  const handleNewContent = () => {
    state.newContent.initialize()
  }
</script>

<div class="root flex flex-row min-h-[3rem] max-h-[3rem]">
  {#if $isLoading}
    <MyButton id="qa-test-home" className="add-btn opacity-50" kind="ghost" icon={Add} />
  {:else}
    <MyButton
      id="qa-test-home-loaded"
      onClick={handleNewContent}
      className="add-btn"
      kind="ghost"
      tooltipPosition="bottom"
      tooltip="Create new content"
      tooltipAlignment="center"
      icon={Add}
    />
  {/if}
</div>

<style global lang="postcss">
  .root :global(.cls-1) {
    stroke: white;
  }
  .root :global(.my-button) {
    @apply bg-transparent;
  }

  .root :global(.add-btn > .my-tooltip > div > .bx--tooltip--shown) {
    bottom: -47px;
    margin-inline-start: 20px;
  }
  .root :global(.add-btn svg) {
    @apply h-9 w-9;
    transition: transform 0.3s ease;
  }

  .root :global(.add-btn svg:hover) {
    transform: rotate(90deg);
  }
  .root :global(.add-btn svg:active) {
    transform: rotate(180deg);
  }
  .root :global(.add-btn svg) {
    @apply h-9 w-9;
  }
</style>

import { Content } from 'common/src/types'
import { db } from '../../../business-logic/db-facade'
import { globalPool } from '../../../business-logic/pool-aggregator'
import { State } from '../../../store/state'
import { contentCloseModal } from '../../content/on-content-close'
import { forceUnlock } from '../../content/toolbar/toolbar'

export const drop = (data: any, props: any, tabs: any) => {
  if (data.contentId !== props.contentId) {
    tabs.move(data.contentId, props.contentId)
  }
}

export const close = async (contentId: string, state: State, modal = contentCloseModal, _db = db) => {
  const { data: content } = await _db(globalPool, state).getSingle(contentId)

  if (content && content.dirty) {
    await modal(state, content, async () => {
      state.tabs.removeTab(contentId)
      unlockIfNeeded(content, state)
    })
  } else {
    state.tabs.removeTab(contentId)
    unlockIfNeeded(content, state)
  }
}

export const unlockIfNeeded = async (content: Content, state: State, _unlockFn = forceUnlock) => {
  if (state.contentIsInEdit(content.id)) {
    return _unlockFn(content)
  }
}

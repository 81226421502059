<script lang="ts">
  import { onMount } from 'svelte'
  import { Button, Popover } from 'carbon-components-svelte'
  import UserAvatar from 'carbon-icons-svelte/lib/UserAvatar.svelte'
  import 'carbon-components-svelte/css/all.css'

  import { state } from '../../../store/state'
  import { User } from '../../../store/user'

  import MyButton from '../../atoms/my-button.svelte'

  import { displayedName, logout, onExperimentalToggle } from './user-menu'

  let open = false
  let ref: any = null
  let user: User | undefined

  const unsubscribe = state.loggedUser.subscribe((loggedUser) => {
    user = loggedUser
  })

  const onToggleLogout = () => {
    open = !open
  }

  const onLogout = async () => {
    await logout()
  }

  $: name = displayedName(user)

  onMount(() => {
    return () => unsubscribe()
  })
</script>

<div
  id="qa-test-username-btn"
  bind:this={ref}
  class="user-menu flex items-center justify-center place-content-center ml-auto"
>
  <MyButton
    kind="ghost"
    className="user-btn"
    onClick={onToggleLogout}
    tooltipPosition="bottom"
    tooltip="You"
    tooltipAlignment="end"
  >
    <UserAvatar size={24} color="white" />
  </MyButton>
  <Popover
    id="logout-popover"
    class="user-menu-popover"
    align="bottom"
    bind:open
    on:click:outside={({ detail }) => {
      open = ref.contains(detail.target)
    }}
  >
    <div class="flex flex-col items-center justify-center p-4 gap-4">
      <div class="flex relative">
        <UserAvatar size={48} />
        <button class="activation-zone" on:dblclick={onExperimentalToggle}></button>
      </div>
      <div class="flex flex-col items-center gap-1">
        <span>{name}</span>
      </div>
      <div class="flex flex-col gap-2">
        <Button id="qa-test-auth-login" kind="primary" on:click={onLogout}>Logout</Button>
      </div>
    </div>
  </Popover>
</div>

<style lang="postcss">
  .user-menu :global(.bx--popover-contents) {
    top: 0.9rem;
    right: 3.6rem;
  }

  .user-menu {
    position: relative;
  }

  .user-menu :global(.bx--popover-contents) {
    transform: translateY(-0.5rem);
  }
  .user-menu :global(.bx--btn--ghost) {
    background-color: var(--accent);
  }

  .user-menu :global(.user-btn > .my-tooltip > div > .bx--tooltip--shown) {
    bottom: -47px;
    margin-inline-start: 12px;
    min-width: fit-content;
  }
  .activation-zone {
    background-color: transparent;
    width: 1.5rem;
    height: 1.5rem;
    cursor: default;
    position: absolute;
    top: -5px;
    right: -48px;
  }
</style>
